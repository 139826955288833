import React from "react";
// import { Link, graphql } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import services1 from "../images/services1.jpg";
import services2 from "../images/services2.jpg";
// import { FaBed, FaBath, FaWarehouse } from 'react-icons/fa';
import "../css/plans.css";

class ServicesPage extends React.Component {
  render(){

    const serviceItems = ['Construction', 'New Home Construction', 'Excavation', 'Building Services in Weber County, Davis County, Box Elder County', 'New Home Builder', 'Site Work', 'Development', 'Commercial Construction'];

    return (
      <Layout>
        <SEO title="Services" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">Services</h1>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-8">

              <p style={{fontSize: '1.2em'}}>Heritage Craft Homes can help save you time from the first phone call through project completion. One call does it all! We provide many services for your home, and no job is too small! Our service crew is highly knowledgeable and experienced. You can place your trust in our reliable services. Heritage Craft Homes can definitely find the right fit for you! Heritage Craft Homes provides professional Custom Home Building Services that are individually tailored to meet your requirements.</p>

            </div>
            <div className="col-sm-4">
              <img src={services1} className="img-fluid" alt="alt"/>
            </div>
          </div>
          <div className="row mt-4 mb-4">
            <div className="col-sm-4">
              <img src={services2} className="img-fluid" alt="alt"/>
            </div>
            <div className="col-sm-8">

              <ul style={{fontSize: '1.1em'}}>

                {serviceItems.map((text, index) => {
                  return (
                      <li key={'serviceList-' + index}>{text}</li>
                  )
                })}

              </ul>

            </div>
          </div>

        </div>

      </Layout>
    )
  }
};

export default ServicesPage;
